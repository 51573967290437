.btnIcon {
  font-size: 0.65rem;
}

.btnDropdown {
  height: 1.75rem;
  width: 1.75rem;
  line-height: 0.85rem;
}

.dropdownMenu {
  top: 55px;
  right: 4px;
}

.imageWrapper {
  min-width: 40px;
  width: 40px;
  height: 40px;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}
