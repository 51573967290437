.header h1,
.header h2,
.header h3,
.header h4,
.header h5,
.header h6 {
  line-height: 1.5;
  margin: 25px 0 15px 0;
}

.header h1 {
  font-size: 1.85rem;
}

.header h2 {
  font-size: 1.6rem;
}

.header h3 {
  font-size: 1.45rem;
}

.header h4 {
  font-size: 1.3rem;
}

.header h5 {
  font-size: 1.15rem;
}

.header h6 {
  font-size: 1rem;
}

/* Solve issue with sticky nav in pkg detail page */
.headerAnchor {
  top: -80px;
}

.headingLink {
  margin-left: -35px;
  width: 35px;
  opacity: 0;
  padding-top: 4px;
}

.headingLink svg {
  width: 16px;
  height: 16px;
}

@media (hover: hover) {
  .headingWrapper:hover .headingLink {
    opacity: 1;
  }
}

@media only screen and (max-width: 991.98px) {
  .headingLink {
    margin-left: -25px;
    width: 25px;
  }

  .headingLink svg {
    width: 13px;
    height: 13px;
  }
}

@media only screen and (max-width: 575.98px) {
  .headingLink {
    opacity: 1;
  }
  .header h1 {
    font-size: 1.35rem;
  }

  .header h2 {
    font-size: 1.25rem;
  }

  .header h3 {
    font-size: 1.2rem;
  }

  .header h4 {
    font-size: 1.15rem;
  }

  .header h5 {
    font-size: 1.05rem;
  }

  .header h6 {
    font-size: 1rem;
  }
}
