.modal {
  max-width: 90%;
  width: 400px !important;
  height: 625px;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}
