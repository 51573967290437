.btnDropdown {
  height: 1.75rem;
  width: 1.75rem;
  line-height: 0.85rem;
}

.dropdownMenu {
  top: 55px;
  right: 4px;
}

.btnIcon {
  font-size: 0.65rem;
}

.copyBtnWrapper {
  margin-top: -18px;
}

.copyBtn {
  min-width: 30px;
}

@media only screen and (max-width: 575.98px) {
  .titleCard {
    font-size: 1rem;
  }
}
