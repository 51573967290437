.tooltip {
  top: 0;
}

.tooltipArrow {
  margin-top: 7px;
}

.centerAligned {
  left: 50%;
}

.leftAligned {
  left: 0;
}

.rightAligned {
  right: 0;
}

.rightAligned .tooltip {
  left: auto;
  right: 0;
}

.centerAligned .tooltipArrow {
  left: calc(50% - 3px);
}

.leftAligned .tooltipArrow {
  left: 10px;
}

.rightAligned .tooltipArrow {
  left: auto;
  right: 10px;
}

.tooltipArrow::before {
  border-bottom-color: var(--bs-dark) !important;
}

.tooltipContent {
  background-color: var(--bs-dark) !important;
}
