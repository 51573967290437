.closeModalBtn {
  margin: -1rem;
}

.icon {
  top: -2px;
}

.trackingWarningBtn {
  min-width: 20px;
  line-height: 1.15;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}
