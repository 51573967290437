.content {
  height: 1px;
}

.codeWrapper {
  overscroll-behavior: contain;
  border-color: var(--color-black-15) !important;
  background-color: var(--extra-light-gray);
}

:global(.linenumber) {
  user-select: none;
}

:global(.customYAML .hljs-attr),
:global(.customYAML .hljs-bullet) {
  color: var(--color-font);
}

:global(.customYAML .hljs-comment) {
  color: var(--mid-gray);
}

[data-theme='light'] :global(.customYAML .hljs-comment) {
  color: var(--color-black-35);
}

[data-theme='light'] :global(.customYAML .hljs-string ~ .hljs-number),
[data-theme='light'] :global(.customYAML .hljs-string) {
  color: #ff9800;
}

:global(.customYAML span),
:global(.customYAML .hljs-string) {
  line-height: 1.5rem;
  color: #ffc107;
}

:global(.customYAML .hljs-literal),
:global(.customYAML .hljs-number) {
  color: #dc3545;
}

:global(.customYAML .hljs-string ~ .hljs-number) {
  color: #ffc107;
}

[data-line-number]::before {
  content: attr(data-line-number);
  position: absolute;
  width: 4rem;
  text-align: right;
  left: -4.25rem;
  padding-right: 1rem;
  color: var(--mid-gray);
  user-select: none;
  font-size: 0.8rem;
  line-height: 1.5rem;
}

[data-theme='light'] [data-line-number]::before {
  color: var(--color-black-35);
}

[data-clickable-line='true'] {
  cursor: pointer;
}

[data-clickable-line='true']:hover::after {
  content: '';
  position: absolute;
  background-color: var(--color-black-5);
  top: 0;
  height: 1.5rem;
  width: calc(100% + 2rem);
  left: -0.5rem;
  pointer-events: none;
}

[data-clickable-line='true']:hover::before {
  color: var(--color-black-90);
}

[data-line-number] {
  display: block;
}

[data-active-line='true']::before {
  color: var(--color-black-90);
}

[data-active-line='true']::after {
  content: '';
  position: absolute;
  background-color: var(--color-black-5);
  top: 0;
  height: 1.5rem;
  width: calc(100% + 2rem);
  left: -0.5rem;
  border-left: 2px solid var(--color-font);
}

.dropdown {
  left: 4rem;
}

.arrow {
  right: auto;
}

.versionsDropdown {
  margin-top: 2.35rem;
  width: 14rem;
  height: 17rem;
  font-size: 0.9rem;
}

.selectWrapper {
  max-width: 100%;
  width: 120px;
}

.removeVersionBtn {
  border-left: 1px solid var(--bs-white) !important;
}

.versionsList {
  max-height: calc(100% - 40px);
}

.btnItem {
  padding: 0.5rem 1rem;
}

.itemIcon {
  width: 14px;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 576px) {
  .modalDialog {
    max-width: 80% !important;
    min-height: calc(100% - 10rem) !important;
    max-height: calc(100% - 10rem) !important;
    margin: 5rem auto !important;
  }
}

@media only screen and (max-height: 700px) {
  .modalDialog {
    min-height: calc(100% - 6rem) !important;
    max-height: calc(100% - 6rem) !important;
    margin: 3rem auto !important;
  }

  .modalFooter {
    display: none;
  }

  .title {
    font-size: 1.35rem;
  }
}
