.modal {
  max-width: 90%;
  width: 620px !important;
}

.label {
  font-size: 0.875rem;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 768px) {
  .fieldFeedback {
    position: absolute;
  }
}
