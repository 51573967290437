.tooltip {
  bottom: -40px;
}

.tooltipArrow {
  margin-top: 7px;
  right: 10px;
}

.tooltip.isDark .tooltipArrow::before {
  border-bottom-color: var(--bs-dark);
}

.tooltip.isLight .tooltipArrow::before {
  border-bottom-color: #a3a3a6;
}

.tooltip.isDark .tooltipContent {
  background-color: var(--bs-dark) !important;
}

.tooltip.isLight .tooltipContent {
  background-color: #a3a3a6 !important;
  color: #38383f;
}

.btn {
  height: 32px;
  width: 32px;
}
