.tabs {
  border-bottom-color: var(--color-1-900) !important;
}

.btn {
  margin-bottom: -1px;
}

.btn:hover {
  text-decoration: none;
}

.active {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: var(--color-1-900) !important;
  border-bottom: 1px solid var(--color-1-500) !important;
}
