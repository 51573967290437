.tableTitle {
  font-size: 0.875rem;
}

.btnWrapper {
  cursor: default;
}

.linkBtn {
  margin-left: -12px;
  width: 35px;
  opacity: 0;
  padding-top: 4px;
  transition: none;
  z-index: 1;
}

.inTarget {
  margin-left: -36px;
}

.inSection {
  margin-left: -34px;
  margin-top: -3px;
  width: 30px;
}

.linkBtn svg {
  width: 12px;
  height: 12px;
}

.btn:focus {
  text-decoration: none;
}

@media (hover: hover) {
  .btn:hover {
    text-decoration: none;
  }

  .btnWrapper:hover .linkBtn {
    opacity: 1;
  }
}
