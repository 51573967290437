.filters {
  min-width: 175px;
}

.resetBtnWrapper {
  padding-top: 1px;
}

.resetBtn {
  font-size: 0.75rem;
}

.resetBtnDecorator {
  font-size: 0.8rem;
  height: 1.5em;
}

.checkbox {
  font-size: 0.8rem;
}

.tooltipIcon {
  font-size: 0.85rem;
  top: -1px;
  left: -3px;
}

.tooltipMessage {
  margin-left: -3px;
}

@media only screen and (max-width: 767.98px) {
  .filters {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 575.98px) {
  .facet:first-child {
    margin-top: 0 !important;
  }

  .tooltipIcon {
    left: 5px;
  }
}
