.wrapperCell {
  table-layout: fixed;
}

.badge {
  box-shadow: inset 0 0 2px var(--color-black-15);
}

.link {
  top: -2px;
}

.clickableCell:hover {
  cursor: pointer;
}

.noClickableCell:hover,
.noClickableCell:hover td {
  background-color: var(--bs-white) !important;
  box-shadow: none;
}

/* Markdown */

.mdHeader {
  font-size: 55%;
}

.md p code {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.inlineCode {
  hyphens: none;
  color: var(--bs-dark);
  background-color: var(--code-bg-md);
  border-color: var(--color-1-20) !important;
  padding: 0 0.15rem;
}
