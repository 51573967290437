.badgeFilter {
  font-size: 85%;
  background-color: var(--badge-bg);
  border-color: var(--gray) !important;
  color: var(--color-font);
}

.btn {
  line-height: 1.15;
  opacity: 0.5;
}

.btnIcon {
  top: -1px;
  color: var(--color-font);
}

@media (hover: hover) {
  .btn:hover {
    opacity: 1;
  }
}
