.iconWrapper {
  width: 2rem;
  height: 2rem;
  padding: 2px;
}

.dropdown {
  width: 21rem;
  top: 120% !important;
}

.arrow {
  right: 0.3rem !important;
}

.dropdownItem:not(.isDisabled):hover {
  background-color: var(--color-black-5);
}

.dropdownItem + .dropdownItem {
  border-top: 1px solid var(--border-md);
}

.isDisabled {
  cursor: auto;
}

.offNotifications {
  color: var(--mid-gray);
}

:global(.btn):hover .offNotifications {
  color: var(--bg-white);
}

.tooltip {
  margin-left: -3px;
}

.spinner {
  width: 2rem;
  height: 2rem;
  top: -1px !important;
}

.spinner:before,
.spinner:after {
  width: 1.5rem;
  height: 1.5rem;
  left: 3px;
  top: 4px;
}

@media only screen and (max-width: 767.98px) {
  .dropdown {
    width: 18rem;
    right: -5rem;
  }

  .arrow {
    right: 6.25rem !important;
  }
}
