.active {
  animation: fadeIn 0.25s 1 ease-in-out forwards;
  z-index: 1080;
}

.modal {
  overflow-y: auto;
}

.content {
  border-radius: 0 !important;
  border-color: var(--color-1-500) !important;
  box-shadow: 0px 0px 5px 0px var(--color-1-20) !important;
}

.visibleContentBackdrop::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-black-25);
  z-index: 10;
}

.activeBackdrop {
  animation: fadeIn50 0.15s 1 ease-in-out forwards;
}

.btn:disabled {
  cursor: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn50 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.modal pre:not(:global(.customYAML)):not(:global(.diffTemplate)) {
  padding: 16px !important;
  border-radius: 0;
  white-space: nowrap;
}

.header {
  background-color: var(--color-1-10);
}

@media only screen and (max-width: 575.98px) {
  .content {
    height: calc(90% - 1rem);
    width: 90%;
    margin: auto;
  }

  .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
