.btn {
  font-size: 90%;
  left: -1px;
}

.modal {
  max-width: 80%;
  width: 620px !important;
}

.modalBig {
  width: 840px !important;
}

.summary {
  font-size: 70%;
  top: -1px;
}

@media only screen and (min-width: 576px) {
  .modalDialog {
    max-width: 80% !important;
    min-height: calc(100% - 10rem) !important;
    max-height: calc(100% - 10rem) !important;
    margin: 5rem auto !important;
  }
}

@media only screen and (max-height: 700px) {
  .modalDialog {
    min-height: calc(100% - 6rem) !important;
    max-height: calc(100% - 6rem) !important;
    margin: 3rem auto !important;
  }

  .modalFooter {
    display: none;
  }

  .title {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}
