.btn {
  top: -2px;
}

.imageWrapper {
  min-height: 125px;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}
