.imageWrapper {
  width: 50px;
  min-width: 50px;
  height: 50px;
}

.image {
  max-width: 100%;
  max-height: 100%;
}
