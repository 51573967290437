.templateWrapper {
  border-color: var(--color-black-15) !important;
  background-color: var(--extra-light-gray);
}

.pre {
  font-size: 80%;
  overscroll-behavior: contain;
  padding: 0 !important;
}

.alert {
  background-color: var(--color-black-5);
  font-size: 0.9rem;
  white-space: normal;
}

.errorAlert {
  white-space: pre-wrap;
  width: calc(100% - 6rem);
}

.wrapper {
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

.btn {
  height: 32px;
  width: 32px;
  line-height: 0.75rem;
  box-shadow: 0px 0px 0px 8px var(--extra-light-gray);
}

.btn svg {
  margin-left: -3px;
}
