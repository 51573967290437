.labelWrapper {
  margin-top: 4px;
}

.btnIcon {
  font-size: 0.65rem;
}

.modal code {
  word-wrap: normal !important;
  white-space: pre !important;
}

.btnDropdown {
  height: 1.75rem;
  width: 1.75rem;
  line-height: 0.85rem;
}

.dropdownMenu {
  top: 55px;
  right: 4px;
}

.copyBtnWrapper {
  margin-top: -18px;
}

.copyBtn {
  min-width: 30px;
}

.modalSyntaxWrapper {
  padding: 1rem 0;
}

.modalSyntaxTrackerWrapper {
  padding: 0 0 1rem 0;
}

.trackingTime {
  margin-top: 1rem;
}

@media only screen and (max-width: 575.98px) {
  .titleCard {
    font-size: 1rem;
  }

  .btn {
    padding: 0.25em 0.4em;
    font-size: 85%;
    line-height: 1.05rem;
  }
}

@media only screen and (min-width: 576px) {
  .modalDialog {
    max-width: 80% !important;
    min-height: calc(100% - 10rem) !important;
    max-height: calc(100% - 10rem) !important;
    margin: 5rem auto !important;
  }
}

@media only screen and (max-height: 700px) {
  .modalDialog {
    min-height: calc(100% - 6rem) !important;
    max-height: calc(100% - 6rem) !important;
    margin: 3rem auto !important;
  }

  .modalFooter {
    display: none;
  }

  .title {
    font-size: 1.35rem;
  }

  .modalSyntaxWrapper,
  .modalSyntaxTrackerWrapper {
    padding: 0;
  }

  .modalSyntaxWrapper pre {
    margin-bottom: 0 !important;
  }

  .trackingTime {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}
