.versionCell {
  min-width: 50%;
  max-width: 75%;
}

.badge {
  border-color: var(--bs-orange) !important;
  color: var(--bs-orange);
}

.badge.isHighlighted {
  border-color: var(--highlighted) !important;
  color: var(--highlighted);
}

.activeVersion {
  padding-right: 0.75rem;
  min-width: 60px;
}

.versionBtn {
  min-width: 60px;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}
