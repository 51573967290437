.alertWrapper {
  transition: max-height 1s;
  max-height: 0;
}

.isAlertActive {
  max-height: 300px;
}

@media (prefers-reduced-motion: reduce) {
  .alertWrapper {
    transition: max-height 2s;
  }
}
