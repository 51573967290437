.badge {
  font-size: 0.5rem;
}

.inactiveBadge {
  opacity: 0.5;
}

.btnDropdown {
  height: 1.75rem;
  width: 1.75rem;
  line-height: 0.85rem;
}

.dropdownMenu {
  top: 55px;
  right: 4px;
}

.btnIcon {
  font-size: 0.65rem;
}

.badgeNoPackages {
  cursor: pointer;
}

@media only screen and (max-width: 767.98px) {
  .body {
    padding: 1rem !important;
  }

  .badge {
    font-size: 0.6rem !important;
  }
}
