@media only screen and (max-width: 767.98px) {
  .btnAction {
    margin-left: 0.5rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50% !important;
    padding: 0 !important;
    font-size: 1.5rem !important;
  }

  .title {
    font-size: 1.5rem;
  }
}
