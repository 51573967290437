.list {
  border-color: var(--color-black-15) !important;
  z-index: 100;
  top: 31px;
}

@media only screen and (min-width: 1920px) {
  .wrapper {
    width: 30%;
  }
}
