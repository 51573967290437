.code {
  min-height: 100%;
  font-size: 0.85rem;
}

.disabled {
  opacity: 0.75;
}

:global(.cm-s-material-darker.CodeMirror) {
  background-color: var(--extra-light-gray);
}

.disabled :global(.cm-s-material-darker.CodeMirror) {
  background-color: var(--light-gray);
}
