.md {
  z-index: 1;
  margin-top: -20px;
  font-size: 0.95rem;
}

.md p,
.md li,
.md pre,
.md td,
.md blockquote,
.md a {
  line-height: 1.65;
}

.md p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.md p a img {
  vertical-align: auto;
}

.md hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: var(--border-md);
  border: 0;
  display: flex;
}

.md pre {
  background-color: var(--color-1-5) !important;
  padding: 16px !important;
}

.md ul {
  overflow-x: auto;
}

.md ul li:last-child {
  /* Wavefront operator */
  margin-bottom: 0.5rem;
}

.md code {
  word-wrap: normal;
  white-space: pre;
  color: var(--bs-dark);
}

.md *:not(pre):not(td) > code {
  hyphens: none;
  white-space: normal;
  word-wrap: break-word;
  background-color: var(--code-bg-md);
  border: 1px solid var(--color-1-20);
  padding: 0 0.15rem;
}

.md h3 code {
  word-wrap: break-word;
}

.md pre code {
  color: var(--bs-dark);
}

.md p code {
  white-space: normal;
}

.md table {
  margin-bottom: 16px;
  font-size: 0.8rem;
}

.md table th,
.md table tr,
.md table td {
  border: 1px solid var(--border-md);
  padding: 6px 13px;
}

.md table tr:nth-child(2n) {
  background-color: var(--color-1-5) !important;
}

.md table code {
  white-space: normal;
}

.md a {
  overflow-wrap: break-word;
}

.md img {
  max-width: 100%;
}

.btnLink {
  margin-top: -3px;
}

.md table .btnLink {
  font-size: inherit;
}

.paragraph a:only-child {
  display: table-cell;
}

.quote {
  padding-left: 0.75rem;
}

.quote::before {
  content: '';
  position: absolute;
  border-left: 3px solid var(--color-1-500);
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
}

@media only screen and (max-width: 575.98px) {
  .md code {
    font-size: 0.8rem;
  }
}
