.navbarToggler {
  transition: all 0.3s ease-in-out;
}

.iconWrapper {
  width: 2rem;
  height: 2rem;
  border-width: 2px;
  font-size: 1.75rem;
}

.profileImage {
  width: 2rem;
  height: 2rem;
  border-color: var(--bs-white) !important;
}

@media (prefers-reduced-motion: reduce) {
  .navbarToggler {
    transition: all 1.3s ease-in-out;
  }
}
