.metrics {
  min-width: 50%;
}

.legend {
  font-size: 0.8rem;
  min-width: 150px;
}

.badge {
  font-size: 0.75rem;
  opacity: 0.45;
  min-width: 110px;
  border-bottom: 3px solid transparent;
  background-color: var(--color-black-5);
}

.active0 {
  border-color: #4f8500;
}

.active1 {
  border-color: #f1a201;
}

.active2 {
  border-color: #ef6100;
}

.active3 {
  border-color: #d50014;
}

.active4 {
  border-color: #870002;
}
