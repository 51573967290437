@media only screen and (max-width: 767.98px) {
  .title {
    text-transform: none;
    font-size: 1.6rem;
    color: var(--color-font);
  }
}

@media only screen and (min-width: 1400px) {
  .cardsWrapper {
    margin: 0 -0.5rem;
  }
}
