.spinner {
  right: 10px;
  top: 38px;
}

.label {
  font-size: 0.875rem;
}

.revealBtn {
  right: 5px;
}

@media only screen and (min-width: 768px) {
  .inputFeedback {
    position: absolute;
  }

  .inputPwdStrengthError {
    margin-bottom: -1rem;
  }
}
