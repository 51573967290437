.form {
  width: 500px;
  min-width: 60%;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.5rem;
  }
}
