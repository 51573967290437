html,
body,
#root {
  height: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

button:focus,
a:focus,
input:focus,
select:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 transparent !important;
}

.noScroll-sidebar,
.noScroll-modal {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

/* GitHub alerts */
.markdown-alert {
  padding: 0.5rem 1rem;
  margin-bottom: 16px;
  border-left: 0.25em solid var(--github-alert-default-color);
}

.markdown-alert > :first-child {
  margin-top: 0;
}

.markdown-alert > :last-child {
  margin-bottom: 0;
}

.markdown-alert-note {
  border-left-color: var(--github-alert-note-color);
}

.markdown-alert-tip {
  border-left-color: var(--github-alert-tip-color);
}

.markdown-alert-important {
  border-left-color: var(--github-alert-important-color);
}

.markdown-alert-warning {
  border-left-color: var(--github-alert-warning-color);
}

.markdown-alert-caution {
  border-left-color: var(--github-alert-caution-color);
}

.markdown-alert-title {
  display: flex;
  margin-bottom: 4px;
  align-items: center;
}

.markdown-alert-title > svg {
  margin-right: 8px;
}

.markdown-alert-note .markdown-alert-title {
  color: var(--github-alert-note-color);
}

.markdown-alert-tip .markdown-alert-title {
  color: var(--github-alert-tip-color);
}

.markdown-alert-important .markdown-alert-title {
  color: var(--github-alert-important-color);
}

.markdown-alert-warning .markdown-alert-title {
  color: var(--github-alert-warning-color);
}

.markdown-alert-caution .markdown-alert-title {
  color: var(--github-alert-caution-color);
}

@media only screen and (max-width: 991.98px) {
  .noScroll-sidebar.noScroll-lg,
  .noScroll-modal.noScroll-lg {
    overflow: auto !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .noScroll-sidebar.noScroll-md,
  .noScroll-modal.noScroll-md {
    overflow: auto !important;
  }
}

@media only screen and (max-width: 575.98px) {
  .noScroll-sidebar.noScroll-sm,
  .noScroll-modal.noScroll-sm {
    overflow: auto !important;
  }
}
