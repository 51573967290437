.badge {
  height: 16px;
  width: 16px;
  box-shadow: inset 0 0 2px var(--color-black-15);
  top: -1px;
}

.title {
  font-size: 0.7rem;
}

.summary {
  font-size: 0.9rem;
}

.badgeItems {
  background-color: var(--color-black-5);
}

.legend {
  font-size: 75%;
  line-height: 1rem;
}

.disabledBadgeWrapper {
  margin-top: calc(0.5rem + 4px);
}
