.modal {
  max-width: 90%;
  width: 620px !important;
}

.contentWrapper {
  min-height: 140px;
}

.alert {
  font-size: 0.82rem;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}
