.chartWrapper {
  background-color: var(--bs-white) !important;
  padding: 1rem;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  min-height: 350px;
}

[data-theme='dark'] :global(.card).chartWrapper {
  background-color: var(--color-black-5) !important;
}

:global(.apexcharts-tooltip) {
  background-color: var(--bs-white) !important;
  border-color: var(--color-1-900) !important;
  color: var(--font-color) !important;
}

:global(.apexcharts-tooltip-title) {
  background-color: var(--color-black-5) !important;
  border-color: var(--color-1-900) !important;
}

:global(.apexcharts-xaxistooltip) {
  background-color: var(--bs-white) !important;
  color: var(--font-color) !important;
  border-color: var(--color-1-900) !important;
}

:global(.apexcharts-xaxistooltip)::before,
:global(.apexcharts-xaxistooltip)::after {
  border-bottom-color: var(--color-1-900) !important;
}

:global(.apexcharts-selected) svg {
  fill: var(--highlighted) !important;
}

:global(.apexcharts-legend-text) {
  color: var(--font-color) !important;
}

:global(.apexcharts-gridline) {
  position: relative;
  z-index: -1;
}
