.body {
  padding: 0.75rem 0.5em !important;
}

.imageWrapper {
  min-width: 30px;
  width: 30px;
  height: 30px;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.link {
  color: var(--gray-dark);
}

.link:hover {
  color: inherit;
}

.title {
  font-size: 0.8rem;
  line-height: 1rem;
}

.subtitle {
  font-size: 0.7rem;
  margin-top: -0.275rem;
  line-height: 1rem;
}

.subtitle span {
  font-size: 0.65rem;
}

.legend {
  margin-bottom: 0.15rem;
}

.truncateWrapper {
  min-width: 0;
}

.badge {
  height: 17px;
}

.badgeIcon {
  height: 10px;
  width: 10px;
  top: -6px;
}

.starBadge {
  line-height: 0.55rem;
  font-size: 0.55rem;
}

@media (min-width: 1920px) {
  .title {
    font-size: 0.9rem;
  }

  .subtitle {
    font-size: 0.8rem;
  }

  .subtitle span {
    font-size: 0.65rem;
  }
}
