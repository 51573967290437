.anchor {
  margin-top: -20px;
}

.templateWrapper {
  border-color: var(--color-black-15) !important;
  background-color: var(--extra-light-gray);
}

.input[placeholder] {
  text-overflow: ellipsis;
}

.input {
  padding-right: 35px !important;
}

.btn {
  font-size: 0.85rem;
}

.active {
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.legend {
  min-width: 60px;
}

.extraInfo {
  font-size: 90%;
}

.extraInfo .legend {
  min-width: 90px;
}

.btnItemContent {
  font-size: 90%;
}

.label {
  font-size: 0.72rem;
  background-color: var(--badge-bg);
  line-height: 18px;
  padding: 0 5px;
  border-color: var(--gray) !important;
}

.searchIcon {
  font-size: 0.85rem;
  right: 15px;
  top: 9px;
}

.fileWrapper {
  overscroll-behavior: contain;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 576px) {
  .modalDialog {
    max-width: 80% !important;
    min-height: calc(100% - 10rem) !important;
    max-height: calc(100% - 10rem) !important;
    margin: 5rem auto !important;
  }
}

@media only screen and (max-height: 700px) {
  .modalDialog {
    min-height: calc(100% - 6rem) !important;
    max-height: calc(100% - 6rem) !important;
    margin: 3rem auto !important;
  }

  .modalFooter {
    display: none;
  }

  .title {
    font-size: 1.35rem;
  }
}
