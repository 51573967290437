.closeModalBtn {
  margin: -1rem;
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1.25rem;
  }

  .list {
    padding-inline-start: 20px;
  }
}
