.label {
  font-size: 0.875rem;
}

.btn {
  width: 1.25rem;
  height: 1.25rem;
  padding: 2px;
}

.inTitle {
  top: -2px;
}

.btnWrapper {
  width: 2rem;
  top: -2px;
}

.inputWrapper {
  top: 3px;
}

.checkbox,
.checkbox + label {
  cursor: pointer;
}

.checkbox:disabled,
.checkbox:disabled + label {
  cursor: auto;
}

.cellWrapper {
  table-layout: fixed;
}
