.searchBar {
  height: 36px;
  box-shadow: 0 0 0 0.3rem var(--color-black-15);
}

.big {
  height: 50px;
}

.iconWrapper {
  color: var(--color-1-700);
  padding: 0.375rem 0.75rem;
}

.input {
  padding: 0.375rem 0;
  max-width: calc(100% - 5rem);
}

.input:focus {
  background-color: var(--bs-white);
  outline: 0;
}

.inputClean {
  font-size: 0.75rem;
}

.loading {
  right: 35px;
  top: 5px;
}

.bigLoading {
  right: 20px;
  top: 12px;
}

/* Dropdown */
.dropdown {
  min-width: 450px;
  margin-top: 0.5rem;
}

.imageWrapper {
  min-width: 50px;
  width: 50px;
  height: 50px;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.activeDropdownItem {
  background-color: var(--color-black-5);
}

.truncateWrapper {
  min-width: 0;
}

.title {
  font-size: 0.95rem;
  line-height: 1.2;
  font-weight: 500;
}

.smallText {
  font-size: 0.8rem;
}

.midText {
  font-size: 0.9rem;
}

.tipIcon {
  right: -12px;
  top: -18px;
}

.bigTipIcon {
  right: -40px;
}

.kindIcon {
  top: -1px;
}

@media only screen and (max-width: 767.98px) {
  .searchBar {
    height: 30px;
  }

  .loading {
    top: 2px;
  }

  .big {
    height: 40px;
  }

  .big + .loading {
    right: -45px;
    top: 5px;
  }
}

@media only screen and (min-width: 576px) {
  .bigTipIcon {
    right: -37px;
  }
}

@media only screen and (max-width: 575.98px) {
  .iconWrapper {
    padding-left: 0.5rem;
    padding-right: 0;
  }
}
