.subnavbar {
  min-height: 48px;
  padding: 10px 0.5rem;
}

.sidebar {
  width: 230px;
  min-width: 230px;
  margin-top: 2rem;
  margin-right: 1.9rem;
  padding: 1.75rem 1.6rem;
}

[data-theme='dark'] .sidebar {
  border-color: #676869 !important;
}

.btnMobileFilters {
  transition: all 0.3s ease-in-out;
  background-color: var(--color-black-5) !important;
  height: 32px;
  width: 32px;
}

.searchText {
  line-height: 28px;
  font-size: 0.8rem;
}

.filtersBadge::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background-color: #dc3545;
  border: 2px solid var(--color-black-5);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.resetBtnDecorator {
  font-size: 0.8rem;
  height: 1.5em;
}

.list {
  max-width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .list {
    flex-basis: calc(100% - 230px - 1.4rem);
    max-width: calc(100% - 230px - 1.4rem);
  }

  .emptyList {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1399.98px) {
  .list {
    flex-basis: calc(100% - 230px - 1.9rem);
    max-width: calc(100% - 230px - 1.9rem);
  }
}

@media only screen and (min-width: 1400px) {
  .listRow {
    margin-right: calc(-0.675 * var(--bs-gutter-x));
  }
}

@media (hover: hover) {
  .btnMobileFilters:hover {
    background-color: var(--color-black-15) !important;
  }
}

@media only screen and (min-width: 1400px) {
  .sidebar {
    width: 300px;
    min-width: 300px;
    padding: 1.75rem;
  }

  .list {
    flex-basis: calc(100% - 300px - 1.9rem);
    max-width: calc(100% - 300px - 1.9rem);
  }

  .emptyList {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (prefers-reduced-motion: reduce) {
  .btnMobileFilters {
    transition: all 1.3s ease-in-out;
  }
}
