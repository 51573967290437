.wrapper {
  height: 32px;
  border-color: var(--color-black-10) !important;
}

.icon {
  font-size: 70%;
}

.icon path {
  stroke: inherit;
}

@media only screen and (max-width: 991.98px) {
  .wrapper {
    font-size: 0.85rem;
  }
}
