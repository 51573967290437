.label {
  font-size: 0.875rem;
}

.tableWrapper {
  min-width: 550px;
}

.table {
  border-color: var(--border-md) !important;
}

.tableTitle th {
  border-top: 2px solid var(--border-md) !important;
  border-bottom-color: var(--border-md) !important;
}

.tableTitle:hover {
  background-color: inherit !important;
}

.body {
  background-color: var(--bs-white);
}

.image {
  min-width: 25px;
  width: 25px;
  height: 25px;
}

.cellWrapper {
  table-layout: fixed;
}

.legend {
  font-size: 85%;
}

.icon {
  width: 15px;
}

.link u {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.codeWrapper {
  overflow-x: auto;
  background-color: var(--color-black-5);
}

.variablesTable {
  border-color: var(--border-md) !important;
  background-color: var(--bs-white);
}

.closeBtn {
  opacity: 1;
}

.packageCell {
  width: 50%;
}

@media (hover: hover) {
  .closeBtn {
    opacity: 0;
  }

  tr:hover .closeBtn {
    opacity: 1;
  }
}

@media (min-width: 1920px) {
  .btnWrapper {
    max-width: 66.66667%;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 575.98px) {
  .packageCell {
    width: 100%;
  }
}
