.dropdown {
  min-width: 150px;
  max-width: 500px;
  font-size: 0.85rem;
  margin-top: 22px;
  background-color: var(--light-gray);
  border-color: var(--mid-gray);
}

.dropdown::after {
  content: '';
  display: block;
  position: absolute;
  left: 10px;
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 5px solid var(--mid-gray);
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.fixedWidth {
  width: 320px;
}

.content {
  font-size: 80%;
  line-height: 1rem;
}

.content code {
  color: var(--color-1-700);
}

.content .header {
  font-size: 0.75rem;
}

.content p {
  margin-bottom: 0rem;
}

.linkIcon {
  top: -2px;
}

@media only screen and (max-width: 575.98px) {
  .dropdown {
    display: none !important;
  }
}
